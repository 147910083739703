@import "../../asset/css/theme.scss";
.login{
    width: 100%;
    height: 100%;
    .login-img{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .login-saalogo{
        position: fixed;
        top: 24px;
        left: 27px;
        width: 190px;
        height: 40px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .loginQRCode-view{
        width: 310px;
        height: 420px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $color-white;
        border-radius: 10px;
        .loginType{
            position: absolute;
            top: 0px;
            right: 0;
            width: 44px;
            height: 44px;
        }
        .loginQRCode-logo{
            position: absolute;
            top: 35px;
            left: 50%;
            transform: translateX( -50%);
            width: 170px;
            height: 40px;
        }
        .loginQRCode-QC{
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX( -50%);
        }
        .QRExpired{
            width: 213px;
            height: 213px;
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX( -50%);
            background: rgba(0,0,0,0.6);
            &-text{
                padding-top: 77px;
                padding-bottom: 10px;
                font-family: $font-family-PingFang-RG;
                font-size: 16px;
                color: $color-white;
                letter-spacing: 0;
                text-align: center;
            }
            &-Btn{
                width: 116px;
                height: 36px;
                background-color: #FEC900;
                margin-left: 48px;
                border-radius: 4px;
                line-height: 36px;
                font-family: $font-family-PingFang-RG;
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
                text-align: center;
            }
        }
        .openTheHG{
            position: absolute;
            top: 336px;
            left: 50%;
            transform: translateX( -50%);
            font-family: $font-family-PingFang-RG;
            font-size: 16px;
            color: $color-333333;
            letter-spacing: 0;
            text-align: center;
        }
        .ScanQRCode{
            position: absolute;
            top: 364px;
            left: 50%;
            transform: translateX( -50%);
            font-family: $font-family-PingFang-RG;
            font-size: 14px;
            color: $color-999999;
            letter-spacing: 0;
            text-align: center;
        }
    }
    .login-view{
        width: 366px;
        height: 378px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $color-white;
        border-radius: 10px;
        .login-view-logo{
            position: absolute;
            top: 35px;
            left: 50%;
            transform: translateX( -50%);
            width: 170px;
            height: 40px;
        }
        .loginType{
            position: absolute;
            top: 0px;
            right: 0;
            width: 44px;
            height: 44px;
        }
        .tabLogin{
            width: 296px;
            margin: 0 auto;
            margin-top: 100px;
            display: flex;
            .tabLogin_phone,
            .tabLogin_name{
                position: relative;
                flex: 1;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
                text-align: center;
                .tabLoginSel{
                    position: absolute;
                    top: 28px;
                    left: 50%;
                    transform: translate(-50%);
                    width: 42px;
                    height: 2px;
                    background: $color-main;
                    // background: #000;
                }
            }
        }
        .Login_phone,
        .Login_name{
            height: 120px;
            width: 296px;
            margin: 0 auto;
            margin-top: 20px;
            .login-form-button{
                background: $color-main;
                width: 100%;
                border:0px;
            }
        }
    }
}