@import '../../asset/css/theme.scss';
.uploadbox {

    max-width: 900px;
    margin: 0 auto;
    margin-top: 50px;
    .colorinfo{
        color: red;
    }
    .cellitem{
        padding-bottom: 20px;
        margin-top: -16px;
    }
    .TagBox {
        position: relative;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        padding: 5px 11px;
    }
    .placeholderTagBox {
        position: relative;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 100%;
        height: 30px;
        color: #999;
        padding: 5px 11px;
    }

    .file-name {
        line-height: 32px;
    }

    .tagitem {
        line-height: 22px;
        font-variant: tabular-nums;
        display: inline-block;
    }

    .videoDesc {
        height: 150px !important;
    }

    .button-box {
        margin-top: 10px;

        .bt1 {
            width: 100%;
            border: 1px solid #CCCCCC;
            background: #fff;
            color: #333;
            text-shadow: none;
            box-shadow: none
        }

        .bt2 {
            width: 100%;
            color: #333;
            border: 1px solid #FEC900;
            background: #FEC900;
            box-shadow: none
        }
        .BG{
            background: rgb(235, 235, 235);
            border: 1px solid rgb(235, 235, 235);
        }
    }

}

.ClsBox {
    .ant-btn-primary {
        background: #FEC900;
        border: 1px solid #FEC900;
        color: #333;
    }

    .Cls-Box {
        max-height: 600px;
        min-height: 300px;
        overflow-y: scroll;
    }

    .title {
        padding-bottom: 10px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #333333;
    }

    .mb20 {
        margin-top: 20px;
    }

    .classfiybox {
        .classfiyitem {

            div {
                background: #EEEEEE;
                border-radius: 4px;
                color: #333333;
                padding: 4px 10px;
                position: relative;
                width: 105px;

                .text {
                    display: block;
                    overflow: hidden;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                }

                .selectsrc {
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    top: -8px;
                    right: -5px;
                }

            }

            .mb10 {
                padding-bottom: 10px;
            }
        }
    }
}

.color {
    color: #52c41a !important;
}

.ant-modal-confirm-btns {
    .ant-btn-primary {
        color: #333;
        background: #FEC900;
        border-color: #FEC900;
    }
}

.Dragger{
    margin: 0 auto;
    margin-top: 50px;
    // width: 1244px;
    height: 560px;
    background: #F8F8F8;
    border: 0px;
    .ant-upload-drag-icon{
        width: 100px;
        height: 82px;
        margin: 0 auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .ant-upload-text{
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #333333;
        letter-spacing: 0;
    }
}
.DraggerBottom{
    margin: 0 auto;
    margin-top: 66px;
    width: 1244px;
    height: 136px;
    display: flex;
    justify-content: space-between;
    .bottom-div{
        width: 292px;
        height: 136px;
        background: #F6F8F9;
        border-radius: 4px;
        h3{
            margin: 30px 26px 20px 30px;
        }
        p{
            margin: 0 30px;
        }
    }
    .textDecoration{
        text-decoration:underline
    }
}
.imgaa{
    width: 457px;
    height: 1223px;
    background-image: url('../../asset/image/ups.jpg');
}

.uploadImg_uploadBtn1{
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 34px;
    background: $color-main;
    border-radius: 17px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #121212;
    letter-spacing: 0;
    text-align: center;
    line-height: 34px;
    margin: 0 auto;
    overflow: hidden;
    // cursor: pointer;
    opacity: 0;
}
.uploadImg_uploadBtn{
    position: relative;
    width: 120px;
    height: 34px;
    background: $color-main;
    border-radius: 17px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #121212;
    letter-spacing: 0;
    text-align: center;
    line-height: 34px;
    // margin: 0 auto;
}
.displayNone{
    display: none;
}
.uploadImg_img{
    height: 150px;
    width: auto;
    border: 1px solid  #EEEEEE;
}